import React, { useRef, useEffect } from 'react'
import { TimelineMax } from 'gsap'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { calculateFrame, circleAnimationTime } from '../../common/fps'
import { Pagination } from '../../layout/Pagination'
import styled from 'styled-components'
gsap.registerPlugin(CSSPlugin)

const Wrap = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: #222;
`

const AnimationWarp = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
`

const Circle = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: #f6db4c;
	border-radius: 50%;
	overflow: hidden;
`

const HalfCircle = styled.div`
	position: absolute;
	width: 800px;
	height: 500px;
	border-radius: 49%;
	background-color: #222;
	bottom: -300px;
	z-index: 3;
`

const SmallCircleWrap = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 76px;
	height: 25px;
	margin: auto;
	z-index: 2;
`

const SmallCircle = styled.div`
	position: absolute;
	width: 25px;
	height: 25px;
	background-color: #222;
	border-radius: 50%;
`

const Wheat = styled.img`
	position: absolute;
	width: 26px;
	height: 64px;
`

const WheatBK = styled.img`
	position: absolute;
	z-index: 1;
	width: 26px;
	height: 64px;
	left: 0;
	right: 0;
	margin: 0 auto;
`

const GropWheat = styled.img`
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
`

const CompanyScenes = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 880px;
	height: 596px;
`

const Phone = styled.img`
	position: relative;
	width: 280px;
	height: 550px;
	/* margin-right: 32px; */
`

const CompanyTitle = styled.div`
	position: relative;
	align-self: flex-start;
	.CNtext {
		display: inline-block;
		color: #222;
		font-size: 134px;
		font-weight: 900;
		line-height: 1.2;
		opacity: 0;
	}
	.ENtext {
		display: inline-block;
		color: white;
		font-size: 134px;
		font-weight: 900;
		line-height: 1.2;
	}
	.ENSmallText {
		font-size: 79px;
		line-height: 1;
	}
	.black {
		color: #222;
	}
	.pl-5 {
		padding-left: 50px;
	}
	.pl-3 {
		padding-left: 20px;
	}
	.slogan {
		font-size: 21px;
		letter-spacing: 30px;
		padding-left: 6px;
	}
	.circleWrap {
		position: relative;
		display: inline-block;
	}
	.redCircle {
		opacity: 0;
		position: absolute;
		z-index: 2;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-color: rgba(255, 0, 0, 0.5);
	}
	.circleText {
		position: relative;
		z-index: 2;
	}
`

export const InitAnimation = () => {
	const circle = useRef(null)
	const halfCircle = useRef(null)
	const small1 = useRef(null)
	const small2 = useRef(null)
	const small3 = useRef(null)
	const small4 = useRef(null)
	const small5 = useRef(null)
	const wheat = useRef(null)
	const wheatBk = useRef(null)
	const gropWheatRef = useRef(null)
	const animationWrapRef = useRef(null)
	const companyScenesRef = useRef(null)
	const phoneRef = useRef(null)
	const cn_1 = useRef(null)
	const cn_2 = useRef(null)
	const cn_3 = useRef(null)
	const cn_4 = useRef(null)
	const cn_5 = useRef(null)
	const cn_6 = useRef(null)
	const cn_7 = useRef(null)
	const cn_8 = useRef(null)
	const en_1 = useRef(null)
	const en_2 = useRef(null)
	const en_3 = useRef(null)
	const en_4 = useRef(null)
	const en_5 = useRef(null)
	const en_6 = useRef(null)
	const en_7 = useRef(null)
	const en_8 = useRef(null)
	const en_9 = useRef(null)
	const en_10 = useRef(null)
	const en_11 = useRef(null)
	const en_12 = useRef(null)
	const en_13 = useRef(null)
	const en_14 = useRef(null)
	const en_15 = useRef(null)
	const en_16 = useRef(null)
	const en_17 = useRef(null)
	const en_18 = useRef(null)
	const en_19 = useRef(null)
	const red_circle1 = useRef(null)
	const red_circle2 = useRef(null)
	const red_circle3 = useRef(null)
	const red_circle4 = useRef(null)
	const red_circle5 = useRef(null)
	const red_circle6 = useRef(null)
	const red_circle7 = useRef(null)
	const red_circle8 = useRef(null)
	const slogan = useRef(null)
	const paginationRef = useRef(null)
	useEffect(() => {
		let tl = new TimelineMax()
		let circleOne = new TimelineMax()
		let circleTwo = new TimelineMax()
		let circleThree = new TimelineMax()
		let circleFour = new TimelineMax()
		let circleFive = new TimelineMax()
		tl.timeScale(1.5)
		tl.to(circle.current, calculateFrame(4), {
			width: 30,
			height: 30,
		})
			.to(circle.current, calculateFrame(4), {
				width: 500,
				height: 500,
				startAt: {
					width: 30,
				},
			})
			.to(circle.current, calculateFrame(2), {
				width: 430,
				height: 430,
			})
			.to(circle.current, calculateFrame(2), {
				width: 500,
				height: 500,
			})
			.to(halfCircle.current, calculateFrame(1), {
				bottom: -112,
			})
			.addLabel('wheatDropDown', '+=0.7')
			.fromTo(
				wheat.current,
				calculateFrame(46),
				{
					top: -100,
				},
				{
					top: 533,
				},
				'wheatDropDown',
			)
			.fromTo(
				wheatBk.current,
				calculateFrame(46),
				{
					top: -272,
				},
				{
					top: 335,
				},
				'wheatDropDown',
			)
			.removeLabel('wheatDropDown')
			.addLabel('DropDownFinnish', '-=0.5')
			.fromTo(
				gropWheatRef.current,
				calculateFrame(1),
				{
					opacity: 0,
				},
				{
					opacity: 1,
				},
				'DropDownFinnish',
			)
			.to(
				halfCircle.current,
				calculateFrame(2),
				{
					bottom: -130,
				},
				'DropDownFinnish',
			)
			.to(halfCircle.current, calculateFrame(4), {
				bottom: -112,
			})
			.removeLabel('DropDownFinnish')
			.addLabel('startGrop')
			.fromTo(
				gropWheatRef.current,
				calculateFrame(4),
				{
					bottom: 212,
				},
				{
					bottom: 385,
				},
				'startGrop',
			)
			.to(
				small1.current,
				calculateFrame(12),
				{
					y: -190,
					opacity: 0,
				},
				'startGrop-=0.1',
			)
			.to(
				small2.current,
				calculateFrame(12),
				{
					y: -150,
					x: 108,
					opacity: 0,
				},
				'startGrop-=0.1',
			)
			.to(
				small3.current,
				calculateFrame(12),
				{
					y: -150,
					x: -68,
					opacity: 0,
				},
				'startGrop-=0.1',
			)
			.to(
				small4.current,
				calculateFrame(12),
				{
					y: -55,
					x: -88,
					opacity: 0,
				},
				'startGrop-=0.1',
			)
			.to(
				small5.current,
				calculateFrame(12),
				{
					y: -55,
					x: 138,
					opacity: 0,
				},
				'startGrop-=0.1',
			)
			.fromTo(
				gropWheatRef.current,
				calculateFrame(3),
				{
					width: 42,
					height: 166,
				},
				{
					width: 70,
					height: 125,
				},
				'startGrop',
			)
			.removeLabel('startGrop')
			.fromTo(
				gropWheatRef.current,
				calculateFrame(4),
				{
					width: 70,
					height: 125,
				},
				{
					width: 55,
					height: 154,
				},
			)
			.fromTo(
				gropWheatRef.current,
				calculateFrame(2),
				{
					width: 55,
					height: 154,
				},
				{
					width: 56,
					height: 146,
				},
			)
			.addLabel('startMinify')
			.to(circle.current, calculateFrame(8), { scale: 0.05 }, 'startMinify')
			.to(gropWheatRef.current, calculateFrame(8), { scale: 0.1, bottom: 376 }, 'startMinify')
			.to(halfCircle.current, calculateFrame(8), { scale: 0.1, bottom: 158 }, 'startMinify')
			.to(wheat.current, calculateFrame(1), { opacity: 0 }, 'startMinify')
			.removeLabel('startMinify')
			.addLabel('changeScenes')
			.to(
				[gropWheatRef.current, halfCircle.current, wheat.current, wheatBk.current],
				0.1,
				{ opacity: 0 },
				'changeScenes',
			)
			.to(circle.current, calculateFrame(13), { scale: 5 }, 'changeScenes')
			.removeLabel('changeScenes')
			.addLabel('mainPageAnimationStage')
			.fromTo(
				phoneRef.current,
				calculateFrame(1),
				{ width: 274, height: 168, opacity: 0 },
				{ width: 246, height: 606, opacity: 1 },
			)
			.to(phoneRef.current, calculateFrame(1), { width: 297, height: 514 })
			.to(phoneRef.current, calculateFrame(1), { width: 281, height: 550 })
			.fromTo(cn_1.current, calculateFrame(6), { opacity: 0, x: 0 }, { opacity: 1, x: 20 }, 'mainPageAnimationStage')
			.to(cn_1.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=0.55')
			.fromTo(
				cn_2.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: 20 },
				'mainPageAnimationStage+=0.3',
			)
			.to(cn_2.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=0.85')
			.fromTo(
				cn_3.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: 20 },
				'mainPageAnimationStage+=0.6',
			)
			.to(cn_3.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=1.15')
			.fromTo(cn_4.current, calculateFrame(6), { opacity: 0, x: 0 }, { opacity: 1, x: 20 }, 'mainPageAnimationStage+=1')
			.to(cn_4.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=1.4')
			.fromTo(
				cn_5.current,
				calculateFrame(6),
				{ opacity: 0, y: 0 },
				{ opacity: 1, y: 20 },
				'mainPageAnimationStage-=0.3',
			)
			.to(cn_5.current, calculateFrame(3), { y: 0 }, 'mainPageAnimationStage+=0.25')
			.fromTo(cn_6.current, calculateFrame(6), { opacity: 0, y: 0 }, { opacity: 1, y: 20 }, 'mainPageAnimationStage')
			.to(cn_6.current, calculateFrame(3), { y: 0 }, 'mainPageAnimationStage+=0.55')
			.fromTo(
				cn_7.current,
				calculateFrame(6),
				{ opacity: 0, y: 0 },
				{ opacity: 1, y: 20 },
				'mainPageAnimationStage+=0.3',
			)
			.to(cn_7.current, calculateFrame(3), { y: 0 }, 'mainPageAnimationStage+=0.85')
			.fromTo(
				cn_8.current,
				calculateFrame(6),
				{ opacity: 0, y: 0 },
				{ opacity: 1, y: 20 },
				'mainPageAnimationStage+=0.6',
			)
			.to(cn_8.current, calculateFrame(3), { y: 0 }, 'mainPageAnimationStage+=1.15')
			.fromTo(en_14.current, calculateFrame(6), { opacity: 0, x: 0 }, { opacity: 1, x: -20 }, 'mainPageAnimationStage')
			.to(en_14.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=0.55')
			.fromTo(
				en_15.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=0.2',
			)
			.to(en_15.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=0.75')
			.fromTo(
				en_16.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=0.4',
			)
			.to(en_16.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=0.95')
			.fromTo(
				en_1.current,
				calculateFrame(6),
				{ opacity: 0, x: 0, scale: 0.9 },
				{ opacity: 1, x: -20, scale: 1.1 },
				'mainPageAnimationStage+=0.2',
			)
			.to(en_1.current, calculateFrame(3), { x: 0, scale: 1 }, 'mainPageAnimationStage+=0.75')
			.fromTo(
				en_2.current,
				calculateFrame(6),
				{ opacity: 0, x: 0, scale: 0.9 },
				{ opacity: 1, x: -20, scale: 1.1 },
				'mainPageAnimationStage+=0.4',
			)
			.to(en_2.current, calculateFrame(3), { x: 0, scale: 1 }, 'mainPageAnimationStage+=0.95')
			.fromTo(
				en_3.current,
				calculateFrame(6),
				{ opacity: 0, x: 0, scale: 0.9 },
				{ opacity: 1, x: -20, scale: 1.1 },
				'mainPageAnimationStage+=0.6',
			)
			.to(en_3.current, calculateFrame(3), { x: 0, scale: 1 }, 'mainPageAnimationStage+=1.15')
			.fromTo(
				en_4.current,
				calculateFrame(6),
				{ opacity: 0, x: 0, scale: 0.9 },
				{ opacity: 1, x: -20, scale: 1.1 },
				'mainPageAnimationStage+=0.8',
			)
			.to(en_4.current, calculateFrame(3), { x: 0, scale: 1 }, 'mainPageAnimationStage+=1.35')
			.fromTo(
				en_5.current,
				calculateFrame(6),
				{ opacity: 0, x: 0, scale: 0.9 },
				{ opacity: 1, x: -20, scale: 1.1 },
				'mainPageAnimationStage+=1',
			)
			.to(en_5.current, calculateFrame(3), { x: 0, scale: 1 }, 'mainPageAnimationStage+=1.55')
			.fromTo(
				en_6.current,
				calculateFrame(6),
				{ opacity: 0, x: 0, scale: 0.9 },
				{ opacity: 1, x: -20, scale: 1.1 },
				'mainPageAnimationStage+=1.2',
			)
			.to(en_6.current, calculateFrame(3), { x: 0, scale: 1 }, 'mainPageAnimationStage+=1.8')
			.fromTo(
				en_7.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=0.3',
			)
			.to(en_7.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=0.85')
			.fromTo(
				en_8.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=0.5',
			)
			.to(en_8.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=1.1')
			.fromTo(
				en_9.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=0.7',
			)
			.to(en_9.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=1.35')
			.fromTo(
				en_10.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=0.9',
			)
			.to(en_10.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=1.6')
			.fromTo(
				en_11.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=1.1',
			)
			.to(en_11.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=1.85')
			.fromTo(
				en_12.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=1.3',
			)
			.to(en_12.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=2.1')
			.fromTo(
				en_13.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=1.5',
			)
			.to(en_13.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=2.35')
			.fromTo(
				en_17.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=0.5',
			)
			.to(en_17.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=1.1')
			.fromTo(
				en_18.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=0.7',
			)
			.to(en_18.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=1.35')
			.fromTo(
				en_19.current,
				calculateFrame(6),
				{ opacity: 0, x: 0 },
				{ opacity: 1, x: -20 },
				'mainPageAnimationStage+=0.9',
			)
			.to(en_19.current, calculateFrame(3), { x: 0 }, 'mainPageAnimationStage+=1.6')
			.fromTo(slogan.current, calculateFrame(9), { opacity: 0 }, { opacity: 1 }, 'mainPageAnimationStage+=0.8')
			.removeLabel('mainPageAnimationStage')
			.addLabel('startCircleAnimation')
			.fromTo(
				red_circle6.current,
				calculateFrame(5),
				{ opacity: 0, bottom: 25, left: 46 },
				{ opacity: 1, bottom: 102, left: 52 },
				'startCircleAnimation',
			)
			.to(red_circle6.current, calculateFrame(6), { left: 128 }, 'startCircleAnimation+=0.508')
			.to(red_circle6.current, calculateFrame(3), { left: 123 }, 'startCircleAnimation+=1.058')
			.fromTo(
				red_circle5.current,
				calculateFrame(5),
				{ opacity: 0, bottom: 98, left: 32 },
				{ opacity: 1, bottom: 57, left: -10 },
				'startCircleAnimation+=0.04',
			)
			.to(red_circle5.current, calculateFrame(6), { left: 74, bottom: 17 }, 'startCircleAnimation+=0.512')
			.to(red_circle5.current, calculateFrame(3), { left: 68, bottom: 19 }, 'startCircleAnimation+=1.062')
			.fromTo(
				red_circle4.current,
				calculateFrame(5),
				{ opacity: 0, bottom: 10, left: 105 },
				{ opacity: 1, bottom: 108 },
				'startCircleAnimation+=0.05',
			)
			.to(red_circle4.current, calculateFrame(6), { left: 38 }, 'startCircleAnimation+=0.517')
			.to(red_circle4.current, calculateFrame(3), { left: 43 }, 'startCircleAnimation+=1.067')
			.fromTo(
				red_circle1.current,
				calculateFrame(5),
				{ opacity: 0, bottom: 21, left: 3 },
				{ opacity: 1, bottom: 112 },
				'startCircleAnimation+=0.06',
			)
			.to(red_circle1.current, calculateFrame(6), { left: 112 }, 'startCircleAnimation+=0.522')
			.to(red_circle1.current, calculateFrame(3), { left: 107 }, 'startCircleAnimation+=1.072')
			.fromTo(
				red_circle8.current,
				calculateFrame(5),
				{ opacity: 0, bottom: 22, left: 23 },
				{ opacity: 1, bottom: -6, left: 14 },
				'startCircleAnimation+=0.07',
			)
			.to(red_circle8.current, calculateFrame(6), { left: 256 }, 'startCircleAnimation+=0.527')
			.to(red_circle8.current, calculateFrame(3), { left: 251 }, 'startCircleAnimation+=1.077')
			.fromTo(
				red_circle7.current,
				calculateFrame(5),
				{ opacity: 0, bottom: -9, left: 23 },
				{ opacity: 1, left: 239 },
				'startCircleAnimation+=0.08',
			)
			.to(red_circle7.current, calculateFrame(6), { left: 245, bottom: 54 }, 'startCircleAnimation+=0.532')
			.to(red_circle7.current, calculateFrame(3), { bottom: 49 }, 'startCircleAnimation+=1.082')
			.fromTo(
				red_circle3.current,
				calculateFrame(5),
				{ opacity: 0, bottom: 126, left: 174 },
				{ opacity: 1, bottom: 112, left: 0 },
				'startCircleAnimation+=0.09',
			)
			.to(red_circle3.current, calculateFrame(6), { left: 1, bottom: 36 }, 'startCircleAnimation+=0.537')
			.to(red_circle3.current, calculateFrame(3), { bottom: 41 }, 'startCircleAnimation+=1.087')
			.fromTo(
				red_circle2.current,
				calculateFrame(5),
				{ opacity: 0, bottom: 112, left: 28 },
				{ opacity: 1, bottom: 106, left: 98 },
				'startCircleAnimation+=0.1',
			)
			.to(red_circle2.current, calculateFrame(6), { left: 110, bottom: -4 }, 'startCircleAnimation+=0.542')
			.to(red_circle2.current, calculateFrame(3), { bottom: 1 }, 'startCircleAnimation+=1.092')
			.fromTo(
				paginationRef.current,
				calculateFrame(5),
				{
					left: 0,
					opacity: 0,
				},
				{
					left: 44,
					opacity: 1,
				},
			)

		circleOne
			.fromTo(
				small1.current,
				circleAnimationTime,
				{
					x: 25,
					y: 0,
				},
				{ x: 25, y: -20, delay: 2.041 },
			)
			.to(small1.current, circleAnimationTime, {
				y: -10,
			})

		circleTwo
			.fromTo(
				small2.current,
				circleAnimationTime,
				{
					x: 58,
					y: 0,
				},
				{ x: 58, y: -20, delay: 2.141 },
			)
			.to(small2.current, circleAnimationTime, {
				y: -7,
			})

		circleThree
			.fromTo(
				small3.current,
				circleAnimationTime,
				{
					x: -7,
					y: 0,
				},
				{ x: -7, y: -20, delay: 2.241 },
			)
			.to(small3.current, circleAnimationTime, {
				y: -7,
			})

		circleFour
			.fromTo(
				small4.current,
				circleAnimationTime,
				{
					x: 10,
					y: 0,
				},
				{ x: 10, y: -25, delay: 2.341 },
			)
			.to(small4.current, circleAnimationTime, {
				y: -10,
			})

		circleFive
			.fromTo(
				small5.current,
				circleAnimationTime,
				{
					x: 40,
					y: 0,
				},
				{ x: 40, y: -25, delay: 2.441 },
			)
			.to(small5.current, circleAnimationTime, {
				y: -10,
			})
	}, [])

	return (
		<Wrap>
			<Pagination ref={paginationRef}></Pagination>
			<AnimationWarp ref={animationWrapRef}>
				<Wheat ref={wheat} src="./assets/icon/wheat.svg" />
				<Circle ref={circle}>
					<WheatBK ref={wheatBk} src="./assets/icon/wheat_bk.svg"></WheatBK>
				</Circle>
				<HalfCircle ref={halfCircle}>
					<SmallCircleWrap>
						<SmallCircle ref={small1} />
						<SmallCircle ref={small2} />
						<SmallCircle ref={small3} />
						<SmallCircle ref={small4} />
						<SmallCircle ref={small5} />
					</SmallCircleWrap>
				</HalfCircle>
				<GropWheat src="./assets/icon/grop_wheat.png" ref={gropWheatRef} />
				<CompanyScenes ref={companyScenesRef}>
					<Phone src="./assets/icon/phone.png" ref={phoneRef}></Phone>
					<CompanyTitle>
						<span className="CNtext circleText" ref={cn_1}>
							智<span className="redCircle" ref={red_circle1}></span>
						</span>
						<span className="CNtext" ref={cn_2}>
							禾
						</span>
						<span className="CNtext" ref={cn_3}>
							數
						</span>
						<span className="CNtext circleText" ref={cn_4}>
							位<span className="redCircle" ref={red_circle2}></span>
						</span>
						<br />
						<span className="circleWrap">
							<span className="CNtext" ref={cn_5}>
								有
							</span>
							<span className="CNtext" ref={cn_6}>
								限
							</span>
							<span className="redCircle" ref={red_circle3}></span>
						</span>
						<span className="CNtext circleText" ref={cn_7}>
							公<span className="redCircle" ref={red_circle4}></span>
						</span>
						<span className="CNtext" ref={cn_8}>
							司
						</span>
						<br />
						<span className="ENtext black circleText" ref={en_1}>
							C<span className="redCircle" ref={red_circle5}></span>
						</span>
						<span className="ENtext" ref={en_2}>
							h
						</span>
						<span className="ENtext" ref={en_3}>
							i
						</span>
						<span className="ENtext" ref={en_4}>
							h
						</span>
						<span className="ENtext black pl-5 circleText" ref={en_5}>
							H<span className="redCircle" ref={red_circle6}></span>
						</span>
						<span className="ENtext" ref={en_6}>
							e
						</span>
						<br />
						<span className="circleWrap">
							<span className="ENtext ENSmallText" ref={en_7}>
								D
							</span>
							<span className="ENtext ENSmallText" ref={en_8}>
								i
							</span>
							<span className="ENtext ENSmallText" ref={en_9}>
								g
							</span>
							<span className="ENtext ENSmallText" ref={en_10}>
								i
							</span>
							<span className="ENtext ENSmallText" ref={en_11}>
								t
							</span>
							<span className="ENtext ENSmallText" ref={en_12}>
								a
							</span>
							<span className="ENtext ENSmallText" ref={en_13}>
								l
							</span>
							<span className="redCircle" ref={red_circle7}></span>
						</span>
						<span className="circleWrap">
							<span className="ENtext pl-3 ENSmallText" ref={en_14}>
								c
							</span>
							<span className="ENtext ENSmallText" ref={en_15}>
								o
							</span>
							<span className="ENtext ENSmallText" ref={en_16}>
								.
							</span>
							<span className="ENtext pl-3 ENSmallText" ref={en_17}>
								l
							</span>
							<span className="ENtext ENSmallText" ref={en_18}>
								t
							</span>
							<span className="ENtext ENSmallText" ref={en_19}>
								d
							</span>
							<span className="redCircle" ref={red_circle8}></span>
						</span>
						<p className="slogan" ref={slogan}>
							設計與開發讓生活更簡單
						</p>
					</CompanyTitle>
				</CompanyScenes>
			</AnimationWarp>
		</Wrap>
	)
}
