import React from 'react'
import { Logo } from './logo'
import { Menu } from './menu'
import styled from 'styled-components'

const StyledHeader = styled.div`
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 10;
`

export const Header = (props) => {
	return (
		<StyledHeader>
			<Logo color={props.color} />
			<Menu color={props.color} />
		</StyledHeader>
	)
}
