import React, { useContext, Fragment } from 'react'
import context from '../../context/GlobalContext'
import styled from 'styled-components'
import { Item } from './Item'

const StyledPagination = styled.div`
	align-items: center;
	bottom: 47px;
	display: flex;
	justify-content: center;
	margin: auto;
	position: absolute;
	right: 17px;
	z-index: 10;

	${({ theme }) => theme.sm`
    left: 44px;
    right: auto;
    top: 0;
  `};
`
const TopButton = styled.div`
	position: absolute;
	top: -75px;
	bottom: 0;
	margin: auto;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 14px 20px 14px;
	border-color: transparent transparent #222 transparent;
	cursor: pointer;
	&.noPrev {
		&::after {
			content: '';
			position: absolute;
			top: 4px;
			left: -10px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 14px 10px;
			border-color: transparent transparent #f7db4c transparent;
		}
	}
	&.yellow {
		border-color: transparent transparent #f7db4c transparent;
	}
	&.disabled {
		display: none;
	}
`
const BottomButton = styled.div`
	position: absolute;
	top: 0;
	bottom: -75px;
	margin: auto;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 20px 14px 0 14px;
	border-color: #222 transparent transparent transparent;
	cursor: pointer;
	&.noNext {
		border-color: #f7db4c transparent transparent transparent;
		&::after {
			content: '';
			position: absolute;
			bottom: 4px;
			left: -10px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 14px 10px 0 10px;
			border-color: #222222 transparent transparent transparent;
		}
	}
	&.yellow {
		border-color: #f7db4c transparent transparent transparent;
	}
	&.disabled {
		display: none;
	}
`
const StyledList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`
const StyledListItem = styled.li`
	margin: 20px 0;
`

const Pagination = React.forwardRef((props, paginationRef) => {
	const { currentPage, clickPrevButton, clickNextButton, windowSize, routerArr } = useContext(context)
	const classNameValue = (position) => {
		if (position === 'top') {
			switch (currentPage) {
				case 0:
					return 'bk noPrev'
				case 1:
					return 'yellow'
				case 2:
					return 'bk'
				case 3:
					return 'yellow'
				default:
					break
			}
		} else {
			switch (currentPage) {
				case 0:
					return 'bk'
				case 1:
					return 'yellow'
				case 2:
					return 'bk'
				case 3:
					return 'noNext'
				default:
					break
			}
		}
	}
	return (
		<Fragment>
			<StyledPagination ref={paginationRef}>
				{windowSize.width >= 768 ? (
					<Fragment>
						<TopButton onClick={clickPrevButton} className={classNameValue('top')}></TopButton>
						<BottomButton onClick={clickNextButton} className={classNameValue()}></BottomButton>
					</Fragment>
				) : (
					<StyledList>
						{routerArr.map((item, i) => (
							<StyledListItem key={i}>
								<Item target={i}>{i}</Item>
							</StyledListItem>
						))}
					</StyledList>
				)}
			</StyledPagination>
		</Fragment>
	)
})

export { Pagination }
