import React, { useContext, Fragment, useEffect } from 'react'
import context from '../../context/GlobalContext'
import { Header } from '../../layout/Header'
import { InitAnimation } from './InitAnimation'
import { InitAnimationII } from './InitAnimationII'

/**
 * These are root pages
 */
const MainPage = () => {
	const { windowSize, setCurrentPage } = useContext(context)

	useEffect(() => {
		setCurrentPage(0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Fragment>
			<Header color="dark" />
			{windowSize.width > 768 ? <InitAnimation /> : <InitAnimationII />}
		</Fragment>
	)
}

export { MainPage }
