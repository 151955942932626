import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { TimelineMax } from 'gsap'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { StatusCard } from './StatusCard'
import { Pagination } from '../../layout/Pagination/index'
import { Container } from '../../layout/Container'
import { Header } from '../../layout/Header'
import { Title } from '../../layout/Title'
gsap.registerPlugin(CSSPlugin)

const StyledPage = styled.section`
	background: ${({ theme }) => theme.secondaryColor};
	display: block;
	height: 100vh;
	overflow: hidden;
	position: relative;
	width: 100vw;
`

const StyledCardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	> * {
		flex: 0 0 50%;
		margin: 10px;

		${({ theme }) => theme.md`
			flex: 0 0 30%;
		`}
	}
`

export const StatusPage = () => {
	const [cardList] = useState([
		{
			wording: '已結案',
			quantity: '100',
		},
		{
			wording: '執行中',
			quantity: '10',
		},
		{
			wording: '洽談中',
			quantity: '25',
		},
	])
	const [isPlay, setIsPlay] = useState(true)
	let page = useRef()
	let tl = new TimelineMax()
	tl.timeScale(2)

	useEffect(() => {
		// fetchData()
		tl.call(function () {
			setIsPlay(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// useEffect(() => {
	//   tl.call(function () {
	//     setIsPlay(false)
	//   })
	// }, [cardList])

	// const fetchData = () => {
	//   let fetchedData = [
	//     {
	//       wording: '已結案',
	//       quantity: '100',
	//     },
	//     {
	//       wording: '執行中',
	//       quantity: '10',
	//     },
	//     {
	//       wording: '洽談中',
	//       quantity: '25',
	//     },
	//   ]

	//   setCardList((state) => fetchedData)
	// }

	return (
		<StyledPage>
			<Header color="light" />
			<Title title="創意不應該被技術侷限" color="light" />
			<Pagination></Pagination>
			<Container ref={page}>
				<StyledCardContainer>
					{cardList.map((item, i) => {
						return (
							<StatusCard
								key={i}
								isPlay={isPlay}
								direction={item.direction}
								wording={item.wording}
								quantity={item.quantity}
								delay={i}
							/>
						)
					})}
				</StyledCardContainer>
			</Container>
		</StyledPage>
	)
}
