import React from 'react'
import styled from 'styled-components'
import { WaterFallsList } from '../../common/list/WaterFallsList.js'

const StyledModal = styled.div`
	display: flex;
	flex-direction: column;

	${({ theme }) => theme.sm`
    padding: 0 30px;
  `};
`
const StyledHeader = styled.div`
	flex: 1;
	margin-bottom: 1rem;

	${({ theme }) => theme.sm`
		align-items: stretch;
		display: flex;
	`};
`
const StyledSlogan = styled.h2`
	align-items: center;
	background-color: ${({ theme }) => theme.secondaryColor};
	color: ${({ theme }) => theme.primaryColor};
	display: flex;
	flex-wrap: wrap;
	font-size: 20px;
	font-weight: bold;
	justify-content: center;
	line-height: 1.2;
	margin: 0;
	padding: 12px 0 18px;

	${({ theme }) => theme.sm`
		border-radius: ${({ theme }) => theme.radius};
		flex: 1;
		padding: 8px 78px 12px;
		font-size: 31px;
	`}
	${({ theme }) => theme.md`
		font-size: 45px;
		padding: 18px 0 24px;
	`}

	span {
		display: inline-block;
	}
`
const StyledTitle = styled.div`
	background: url(${(props) => props.bg}) no-repeat center center;
	background-size: cover;
	align-items: center;
	color: ${({ theme }) => theme.whiteColor};
	display: flex;
	justify-content: center;
	padding-bottom: calc(100% * 300 / 1470);

	${({ theme }) => theme.sm`
		border-radius: ${({ theme }) => theme.radius};
		padding-bottom: calc(100% * 250 / 1470);
		flex: 3;
		margin-left: 20px;
	`}

	${({ theme }) => theme.lg`
		padding-bottom: calc(100% * 220 / 1470);
		flex: 2;
	`};
`
const StyledContent = styled.div`
	flex: 1;
	overflow: auto;
`
const StyledCover = styled.div`
	background: url(${(props) => props.bg}) no-repeat center center / cover;
	padding-bottom: calc(100% * 860 / 2230);
	text-indent: -9999px;

	${({ theme }) => theme.sm`
		border-radius: ${({ theme }) => theme.radius};
	`};
`
const StyledWording = styled.div`
	background: ${({ theme }) => theme.whiteColor};
	border-radius: ${({ theme }) => theme.radius};
	font-size: 15px;
	margin: 1rem;
	padding: 1.1em 1.1em 1.1em 1.2em;

	${({ theme }) => theme.sm`
		margin: 1rem 0;
	`};

	${({ theme }) => theme.lg`
		font-size: 24px;
	`};
`
const StyledSpecTitle = styled.h3`
	background: ${({ theme }) => theme.secondaryColor};
	color: ${({ theme }) => theme.primaryColor};
	display: inline-flex;
	padding: 0.5em 3em;
	border-radius: 0 ${({ theme }) => theme.radius} ${({ theme }) => theme.radius} 0;

	${({ theme }) => theme.sm`
		border-radius: ${({ theme }) => theme.radius};
	`};
`
const StyledWaterFallsList = styled.div`
	padding: 0 20px;

	${({ theme }) => theme.sm`
    padding: 0;
  `};
`

const ModalWork = (props) => {
	const { data } = props
	// const [currentPosition, setPosition] = useState(0)

	return (
		<StyledModal
		// onTouchStart={(e) => {
		//   console.log('touchStart', e.nativeEvent)
		//   setPosition((prevStates) => (prevStates = e.nativeEvent.changedTouches[0].clientX))
		// }}
		// onTouchEnd={(e) => {
		//   if (e.nativeEvent.changedTouches[0].clientX - currentPosition > 0) {
		//     console.log('right')
		//   } else {
		//     console.log('left')
		//   }
		// }}
		>
			<StyledHeader>
				<StyledSlogan>
					<span>你不用會</span>
					<span>我來實現</span>
				</StyledSlogan>
				<StyledTitle bg={`./assets/images/works/${data[props.index].name}.png`} />
			</StyledHeader>
			<StyledContent>
				<StyledCover bg={`./assets/images/works/${data[props.index].name}_cover.png`}>
					{data[props.index].label}截圖
				</StyledCover>
				<StyledWording>{data[props.index].wording}</StyledWording>
				<StyledSpecTitle>我們使用了以下技術</StyledSpecTitle>
				<StyledWaterFallsList>
					<WaterFallsList list={data[props.index].list} />
				</StyledWaterFallsList>
			</StyledContent>
		</StyledModal>
	)
}

export { ModalWork }
